import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "../styles/components/_ctaSection.scss"

const CtaSection = ({ shadow, ctas }) => {
  return (
    <div className="cta-section">
      <div className="cta-wrapper">
        <div className="cta-box">
          {ctas.map((cta, n) => {
            if (cta.link.includes("//")) {
              return (
                <div className="cta" key={n}>
                  <img
                    src={cta.ctaIcon.file.url}
                    alt={cta.ctaIcon.description}
                    className="cta-icon"
                  />
                  <div className="cta-content">
                    <div className="cta-blurb">
                      {documentToReactComponents(cta.blurb.json)}
                    </div>
                    <a href={cta.link} rel="noopener noreferrer">
                      {cta.buttonText}
                      <span className="button-icon">
                        <img
                          src={cta.buttonIcon.file.url}
                          alt={cta.ctaIcon.description}
                        />
                      </span>
                    </a>
                  </div>
                  <BackgroundImage
                    fluid={shadow.fluid}
                    className="box-shadow shadow"
                  ></BackgroundImage>
                </div>
              )
            } else {
              return (
                <div className="cta" key={n}>
                  <img
                    src={cta.ctaIcon.file.url}
                    alt={cta.ctaIcon.description}
                    className="cta-icon"
                  />
                  <div className="cta-content">
                    <div className="cta-blurb">
                      {documentToReactComponents(cta.blurb.json)}
                    </div>
                    <Link to={`${cta.link}`}>
                      {cta.buttonText}
                      <span className="button-icon">
                        <img
                          src={cta.buttonIcon.file.url}
                          alt={cta.ctaIcon.description}
                        />
                      </span>
                    </Link>
                  </div>
                  <BackgroundImage
                    fluid={shadow.fluid}
                    className="box-shadow shadow"
                  ></BackgroundImage>
                </div>
              )
            }
          })}
        </div>
        <BackgroundImage
          fluid={shadow.fluid}
          className="box-shadow shadow"
        ></BackgroundImage>
      </div>
    </div>
  )
}

export default CtaSection
