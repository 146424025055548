import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "../styles/components/_brandSection.scss"

const brandSection = ({ headline, textDivider, subtitle, title, brands }) => {
  const underlineStyle = {
    backgroundImage: `url(${textDivider.file.url})`,
  }

  return (
    <div className="brand-section">
      <div className="brands-overview">
        <div className="overview-text">
          <h3>{title}</h3>
          <h2>{headline}</h2>
          <div className="underline white" style={underlineStyle}></div>
          <div className="subtitle">{documentToReactComponents(subtitle)}</div>
        </div>
        <div className="overview-triangle"></div>
      </div>
      <div className="brands">
        <ul>
          {brands.map((brand, n) => {
            return (
              <li key={n}>
                <a href={brand.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={brand.brandLogo.file.url}
                    alt={brand.brandLogo.description}
                  />
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default brandSection
