import React from "react"
import { graphql } from "gatsby"
import { navigate } from "gatsby"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import Meta from "../components/meta"
import IntroSection from "../components/introSection"
import BrandSection from "../components/brandSection"
import StatisticsSection from "../components/statisticsSection"
import ImageSection from "../components/imageSection"
import CtaSection from "../components/ctaSection"
import Footer from "../components/footer"
import "../styles/_general.scss"
import "../styles/pages/_index.scss"

export default class Homepage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll)
  }

  render() {
    const homepageData = this.props.data.allContentfulHomepage.nodes[0]
    const searchParam = this.props.location.search
    const generalData = this.props.data.allContentfulGeneral.nodes[0]

    if (searchParam.includes("?action=press")) {
      if (searchParam === "?action=press&date=2019-10-01") {
        navigate("/press-2019-10-07/")
      } else {
        const pubDate = searchParam.replace("?action=press&date=", "")
        navigate(`/press-${pubDate}`)
      }
      return null
    } else {
      return (
        <div className="homepage" key="homepage">
          <Meta
            title={homepageData.seo.title}
            description={homepageData.seo.description.description}
            image={homepageData.seo.socialImage.file.url}
            url={this.props.location.href}
            imgAlt={homepageData.seo.socialImage.description}
          />
          <Header />
          <IntroSection
            headline={homepageData.headline}
            subtext={homepageData.subtext.json}
            scrollIcon={homepageData.scrollIcon}
          />
          <BrandSection
            headline={homepageData.brandsHeadline}
            textDivider={generalData.whiteUnderline}
            subtitle={homepageData.brandsSubtitle.json}
            title={homepageData.brandsTitle}
            brands={homepageData.brands}
          />
          <StatisticsSection
            background={homepageData.statisticsBackground.fluid}
            title={homepageData.statisticsTitle}
            titleIcon={homepageData.statisticsArrows}
            statistics={homepageData.statistics}
          />
          <ImageSection
            images={homepageData.locationImages}
            locationIcon={homepageData.locationIcon}
            text={homepageData.locationText}
          />
          <CtaSection
            shadow={generalData.darkShadow}
            ctas={homepageData.ctAs}
          />
          <Footer />
        </div>
      )
    }
  }
}

export const query = graphql`
  query IndexPageQuery {
    allContentfulHomepage {
      nodes {
        headline
        subtext {
          json
        }
        scrollIcon {
          description
          file {
            url
          }
        }
        brandsHeadline
        brandsSubtitle {
          json
        }
        brandsTitle
        brands {
          brandLogo {
            description
            file {
              url
            }
          }
          url
        }
        statisticsTitle
        statisticsArrows {
          description
          file {
            url
          }
        }
        statistics {
          statistic
          description
          icon {
            description
            file {
              url
            }
          }
          statisticIcon {
            description
            file {
              url
            }
          }
        }
        statisticsBackground {
          fluid {
            ...GatsbyContentfulFluid
          }
          description
        }
        locationImages {
          fluid {
            ...GatsbyContentfulFluid
          }
          file {
            url
          }
          description
        }
        locationIcon {
          description
          file {
            url
          }
        }
        locationText
        ctAs {
          link
          blurb {
            json
          }
          buttonText
          buttonIcon {
            description
            file {
              url
            }
            title
          }
          ctaIcon {
            description
            file {
              url
            }
          }
        }
        seo {
          title
          description {
            description
          }
          socialImage {
            description
            file {
              url
            }
          }
        }
      }
    }
    allContentfulGeneral {
      nodes {
        darkShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        redUnderline {
          description
          file {
            url
          }
        }
        whiteUnderline {
          description
          file {
            url
          }
        }
      }
    }
  }
`
