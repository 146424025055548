import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { InView } from "react-intersection-observer"
import setVisibility from "../functions/setVisibility"
import "../styles/components/_introSection.scss"

const IntroSection = ({ headline, subtext, scrollIcon }) => {
  return (
    <InView
      as="div"
      className="intro-section"
      threshold={0.5}
      onChange={(inView, entry) => setVisibility(inView, entry)}
    >
      <h1>{headline}</h1>
      <div className="subtitle">{documentToReactComponents(subtext)}</div>
      <img
        src={scrollIcon.file.url}
        alt={scrollIcon.description}
        className="scroll-icon"
      />
    </InView>
  )
}

export default IntroSection
