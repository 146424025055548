import React from "react"
import BackgroundImage from "gatsby-background-image"
import "../styles/components/_statisticsSection.scss"

const StatisticsSection = ({ background, title, titleIcon, statistics }) => {
  return (
    <div className="statistics-section">
      <BackgroundImage fluid={background} className="bg">
        <div className="content-wrapper">
          <div className="title">
            <h3>{title}</h3>
            <img src={titleIcon.file.url} alt={titleIcon.description} />
          </div>
          <ul className="statistics">
            {statistics.map((statistic, n) => {
              return (
                <li key={n}>
                  <img
                    src={statistic.icon.file.url}
                    alt={statistic.icon.description}
                    className="statistics-image"
                  />
                  <div className="statistic">
                    {statistic.statistic}
                    <span className="statistic-icon">
                      <img
                        src={statistic.statisticIcon.file.url}
                        alt={statistic.statisticIcon.description}
                      />
                    </span>
                  </div>
                  <div className="description">{statistic.description}</div>
                </li>
              )
            })}
          </ul>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default StatisticsSection
